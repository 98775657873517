import React from 'react';

export const SPDeclarada = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99996 2.33341C4.42263 2.33341 2.33329 4.42275 2.33329 7.00008C2.33329 9.57741 4.42263 11.6667 6.99996 11.6667C9.57729 11.6667 11.6666 9.57741 11.6666 7.00008C11.6666 4.42275 9.57729 2.33341 6.99996 2.33341ZM1.16663 7.00008C1.16663 3.77842 3.7783 1.16675 6.99996 1.16675C10.2216 1.16675 12.8333 3.77842 12.8333 7.00008C12.8333 10.2217 10.2216 12.8334 6.99996 12.8334C3.7783 12.8334 1.16663 10.2217 1.16663 7.00008Z" fill="#121A52" />
    </svg>
);

export const Bajo = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99996 2.33341C4.42263 2.33341 2.33329 4.42275 2.33329 7.00008C2.33329 7.85008 2.56055 8.64701 2.95761 9.33341C2.95761 9.33341 4.98097 9.33341 6.70829 9.33341C8.43562 9.33341 11.0423 9.33341 11.0423 9.33341C11.4394 8.64701 11.6666 7.85008 11.6666 7.00008C11.6666 4.42275 9.57729 2.33341 6.99996 2.33341ZM1.16663 7.00008C1.16663 3.77842 3.7783 1.16675 6.99996 1.16675C10.2216 1.16675 12.8333 3.77842 12.8333 7.00008C12.8333 10.2217 10.2216 12.8334 6.99996 12.8334C3.7783 12.8334 1.16663 10.2217 1.16663 7.00008Z" fill="#1C8747" />
    </svg>
);

export const Mediano = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99996 2.33329C4.42263 2.33329 2.33329 4.42263 2.33329 6.99996C2.33329 6.99996 4.42263 6.99996 6.99996 6.99996C9.57729 6.99996 11.6666 6.99996 11.6666 6.99996C11.6666 4.42263 9.57729 2.33329 6.99996 2.33329ZM1.16663 6.99996C1.16663 3.7783 3.7783 1.16663 6.99996 1.16663C10.2216 1.16663 12.8333 3.7783 12.8333 6.99996C12.8333 10.2216 10.2216 12.8333 6.99996 12.8333C3.7783 12.8333 1.16663 10.2216 1.16663 6.99996Z" fill="#7C6600" />
    </svg>
);

export const MedianoAlto = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99996 2.33329C5.27263 2.33329 3.7645 3.27176 2.95761 4.66663C2.95761 4.66663 3.49996 4.66663 4.66663 4.66663C6.266 4.66663 4.42263 4.66663 6.99996 4.66663C7.76098 4.66663 7.87496 4.66663 9.33329 4.66663C10.2083 4.66663 11.0423 4.66663 11.0423 4.66663C10.2354 3.27176 8.72728 2.33329 6.99996 2.33329ZM1.16663 6.99996C1.16663 3.7783 3.7783 1.16663 6.99996 1.16663C10.2216 1.16663 12.8333 3.7783 12.8333 6.99996C12.8333 10.2216 10.2216 12.8333 6.99996 12.8333C3.7783 12.8333 1.16663 10.2216 1.16663 6.99996Z" fill="#DC6E00" />
    </svg>
);

export const Alto = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.16663 6.99996C1.16663 3.7783 3.7783 1.16663 6.99996 1.16663C10.2216 1.16663 12.8333 3.7783 12.8333 6.99996C12.8333 10.2216 10.2216 12.8333 6.99996 12.8333C3.7783 12.8333 1.16663 10.2216 1.16663 6.99996Z" fill="#CD1812" />
    </svg>
);

export const MuyAlto = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99996 2.33329C4.42263 2.33329 2.33329 4.42263 2.33329 6.99996C2.33329 9.57729 4.42263 11.6666 6.99996 11.6666C9.57729 11.6666 11.6666 9.57729 11.6666 6.99996C11.6666 4.42263 9.57729 2.33329 6.99996 2.33329ZM1.16663 6.99996C1.16663 3.7783 3.7783 1.16663 6.99996 1.16663C10.2216 1.16663 12.8333 3.7783 12.8333 6.99996C12.8333 10.2216 10.2216 12.8333 6.99996 12.8333C3.7783 12.8333 1.16663 10.2216 1.16663 6.99996Z" fill="#870F00" />
        <path d="M6.99996 8.16663C6.67779 8.16663 6.41663 7.90546 6.41663 7.58329L6.41663 4.08329C6.41663 3.76113 6.67779 3.49996 6.99996 3.49996C7.32213 3.49996 7.58329 3.76113 7.58329 4.08329L7.58329 7.58329C7.58329 7.90546 7.32213 8.16663 6.99996 8.16663Z" fill="#870F00" />
        <path d="M6.12496 9.62496C6.12496 9.14171 6.51671 8.74996 6.99996 8.74996C7.48321 8.74996 7.87496 9.14171 7.87496 9.62496C7.87496 10.1082 7.48321 10.5 6.99996 10.5C6.51671 10.5 6.12496 10.1082 6.12496 9.62496Z" fill="#870F00" />
    </svg>
);

export const NoDisponible = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.26035 4.26035C4.60748 3.91322 5.1703 3.91322 5.51743 4.26035L8 6.74292L10.4826 4.26035C10.8297 3.91322 11.3925 3.91322 11.7397 4.26035C12.0868 4.60748 12.0868 5.1703 11.7397 5.51743L9.25708 8L11.7397 10.4826C12.0868 10.8297 12.0868 11.3925 11.7397 11.7397C11.3925 12.0868 10.8297 12.0868 10.4826 11.7397L8 9.25708L5.51743 11.7397C5.1703 12.0868 4.60748 12.0868 4.26035 11.7397C3.91322 11.3925 3.91322 10.8297 4.26035 10.4826L6.74292 8L4.26035 5.51743C3.91322 5.1703 3.91322 4.60748 4.26035 4.26035Z" fill="#555555" />
    </svg>
);